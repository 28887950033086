<template>
  <div class="">
    <div class="form-row" v-if="contactId">
      <DetailOrderInventoryInputInventory
        placeholder="..."
        class="col-12"
        select="id"
        :contactId="contactId"
        :docType="docType"
        :templateType="templateType"
        v-model="selectedInventory"
        @change="inventory = $event"
        @group="setGroup">
      </DetailOrderInventoryInputInventory>
    </div>

    <div class="form-row" v-if="contactId">
      <DetailOrderInventoryStockBalance
        v-if="selectedInventory"
        label="ราคา"
        class="col-6 col-sm-4"
        :inventoryDocConfigId="selectedInventory"
        :inventoryId="inventory.inventoryId"
        :docType="docType"
        :templateType="templateType">
      </DetailOrderInventoryStockBalance>

      <sgv-input-number
        v-if="selectedInventory"
        class="col-6 col-sm-4"
        label="จำนวน"
        :precision="2"
        v-model="qty">
      </sgv-input-number>

      <DetailOrderInventoryInputPrice
        v-if="selectedInventory"
        label="ราคา"
        :type="selectedGroup"
        class="col-6 col-sm-4"
        :contactId="contactId"
        :inventoryDocConfigId="selectedInventory"
        :qty="qty"
        :docType="docType"
        :templateType="templateType"
        v-model="priceId">
      </DetailOrderInventoryInputPrice>
    </div>
  </div>
</template>

<script>
import DetailOrderInventoryInputInventory from './DetailOrderInventoryInputInventory.vue'
import DetailOrderInventoryInputPrice from './DetailOrderInventoryInputPrice.vue'
import DetailOrderInventoryStockBalance from './DetailOrderInventoryStockBalance.vue'

export default {
  props: {
    contactId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      selectedInventory: null,
      inventory: null,
      qty: 0,
      priceId: null,
      availableStock: 0,
      selectedGroup: null
    }
  },
  methods: {
    setGroup (group) {
      this.selectedGroup = group
      this.selectedInventory = null
      this.qty = 0
    }
  },
  components: {
    DetailOrderInventoryInputInventory,
    DetailOrderInventoryInputPrice,
    DetailOrderInventoryStockBalance
  }
}
</script>

<style lang="css" scoped>
</style>
