<template>
  <div class="">
    <div class="form-row">
      <DetailFormContactInput
        label="ผู้ติดต่อ"
        :docType="docType"
        :templateType="templateType"
        placeholder="..."
        class="col-12"
        select="id"
        v-model="selectedContact">
        <template slot-scope="option">
          {{option.contact.code}} ({{option.contact.name}})
        </template>
      </DetailFormContactInput>
    </div>

    <ListCategoryPriceListInventory
      v-if="selectedContact"
      :docType="docType"
      :templateType="templateType"
      :contactId="selectedContact">
    </ListCategoryPriceListInventory>

    <sgv-table
      class="mt-3"
      ref="docList"
      :rKey="rKey"
      :items="docs"
      :headers="headers"
      :toolbars="toolbars"
      :filter.sync="filterComp"
      :options.sync="options"
      :rowsPerPage="rowsPerPage">

      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.code">
            <router-link
              class="text-decoration-none"
              :to="toDetail(item.id)">
              <span :class="getStatus(item)">
                {{item.code}}
              </span>
            </router-link>
            <div v-if="item.isVoid" class="text-danger">
              <small>ยกเลิก</small>
            </div>
          </td>
          <td v-if="hidden.createdAt">
            {{ item.createdAt | date }}<br>
            <small class="text-primary">{{item.createdUser.name}}</small>
          </td>
          <td v-if="hidden.approvedAt">
            <span v-if="item.approvedAt">
              <span class="text-warning">{{item.approvedAt | date}}</span><br>
              <small class="text-primary">{{item.approvedUser.name}}</small>
            </span>
          </td>
          <td v-if="hidden.closedAt">
            <span v-if="item.closedAt">
              <span class="text-success">{{item.closedAt | date}}</span><br>
              <small class="text-primary">{{item.closedUser.name}}</small>
            </span>
          </td>
          <td v-if="hidden.name">
            {{item.name}}
            <div v-if="item.contact.branch && item.contact.branch !== 'สำนักงานใหญ่'">
              <small class="">
                สาขา: {{item.contact.branch}} 
              </small>
            </div>
            <div v-if="item.remark">
              <small class="text-primary">{{ item.remark }}</small>
            </div>
            <div
              class="no-wrap"
              v-for="repair in item.repairs"
              :key="repair.id">
              <router-link
                :to="toDoc(repair)"
                class="text-decoration-none">
                <small style="white-space: nowrap;">
                  <fa icon="wrench" class="text-danger"></fa>
                  <span class="ml-1">{{repair.code}}</span>
                </small>
              </router-link>
              {{repair.name}}
            </div>
            <div v-if="item.categories.length > 0">
              <small class="text-info">
                <fa icon="object-group"></fa>
                <span class="ml-1">{{mapCategoryText(item.categories)}}</span>
              </small>
            </div>
          </td>
        </tr>
      </template>

      <router-link
        slot="action"
        v-if="$auth.hasRole(`doc:${docType}:add`)"
        :to="toDetail(0)">
        <button
          type="button"
          class="btn btn-link text-success">
          เพิ่ม
        </button>
      </router-link>
    </sgv-table>
  </div>
</template>

<script>
import DetailFormContactInput from './DetailFormContactInput'
import ListCategoryPriceListInventory from './ListCategoryPriceListInventory'
import retainMixin from '@/mixins/retain-mixin'
import {
  LIST_DOC,
  WATCH_DOCS_CREATED,
  WATCH_DOCS_UPDATED,
  WATCH_DOCS_DESTROYED,
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    docStatus: {
      type: String,
      required: false
    },
    categoryId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      docs: [],
      detailView: `Doc${this.$form.capitalize(this.docType)}Detail`,
      rKey: `Doc${this.$form.capitalize(this.docType)}ListCategory`,
      headers: [
        {text: 'รหัส', value: 'code', sort: true, filter: true},
        {text: 'วันที่สร้าง', value: 'createdAt'},
        {text: 'วันทีอนุมัติ', value: 'approvedAt'},
        {text: 'วันที่เสร็จ', value: 'closedAt'},
        {text: 'รายละเอียด', value: 'name', filter: true}
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'createdAt', 'approvedAt', 'closedAt', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      rFields: ['filter', 'options', 'selectedTab'],
      selectedContact: null
    }
  },
  computed: {
    toolbars () {
      const arr = [
        {value: 'filter', icon: 'cog', class: 'text-warning'},
      ]
      return arr
    },
    rowsPerPage () {
      if (!this.docStatus || this.docStatus === 'closedAt') {
        return [
          { text: '10', value: 10 },
          { text: '25', value: 25 },
          { text: '50', value: 50 },
        ]
      } else {
        return [
          { text: 'ทั้งหมด', value: null },
        ]
      }
    },
    filterComp: {
      get () {
        return this.filter
      },
      set (v) {
        const filter = {...v}
        if (v.params && v.params.search) {
          filter.params.search = this.classifyBarcode(v.params.search)
          this.options.search = v.params.search
        }
        this.filter = filter
      }
    }
  },
  apollo: {
    docs: {
      query () {
        return LIST_DOC(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          docType: this.docType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only',
      subscribeToMore: [
        {
          document () {
            return WATCH_DOCS_CREATED(this.templateType)
          },
          variables () {
            return {docType: this.docType}
          },
          updateQuery (previous, { subscriptionData }) {
            const newDocs = [
              subscriptionData.data.docCreated,
              ...previous.docs
            ]
            const result = {
              ...previous,
              docs: newDocs
            }
            return result
          }
        },
        {
          document () {
            return WATCH_DOCS_UPDATED(this.templateType)
          },
          variables () {
            return {docType: this.docType}
          },
          updateQuery (previous, { subscriptionData }) {
            const docIndex = previous.docs.findIndex(v => v.id === subscriptionData.data.docUpdated.id)
            const newDocs = [...previous.docs]
            newDocs[docIndex] = subscriptionData.data.docUpdated

            const result = {
              ...previous,
              docs: newDocs
            }
            return result
          }
        },
        {
          document () {
            return WATCH_DOCS_DESTROYED(this.templateType)
          },
          variables () {
            return {docType: this.docType}
          },
          updateQuery (previous, { subscriptionData }) {
            const docIndex = previous.docs.findIndex(v => v.id === subscriptionData.data.docDestroyed.id)
            if (docIndex === -1) return previous
            const newDocs = [...previous.docs]
            newDocs.splice(docIndex, 1)
            const result = {
              ...previous,
              docs: newDocs
            }
            return result
          }
        }
      ]
    },
  },
  methods: {
    classifyBarcode (txt) {
      if (txt.indexOf('docCode,') === -1) {
        return txt
      } else {
        return txt.replace('docCode,', '')
      }
    },
    getFilter (v) {
      const filter = {
        ...v,
        params: {
          ...v.params,
          docStatus: this.docStatus,
          categoryId: this.categoryId
        }
      }

      return filter
    },
    getStatus (item) {
      if (item.approvedAt && !item.closedAt) return ['text-warning']
      else if (item.closedAt) return ['text-success']
    },
    toDetail (id) {
      return {
        name: this.detailView,
        params: {docId: id}
      }
    },
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id},
        query: {redirect: this.$route.name}
      }
    },
    mapCategoryText (categories) {
      return categories.map(v => v.name).join(', ')
    }
  },
  watch: {
    docStatus(newValue, oldValue) {
      const isOld = oldValue === 'approvedAt' || oldValue === 'createdAt'
      const isNew = !newValue || newValue === 'closedAt'
      if (isOld && isNew) {
        this.filter.limit = 10
      }
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DetailFormContactInput,
    ListCategoryPriceListInventory,
  }
}
</script>

<style lang="css" scoped>

</style>
